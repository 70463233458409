.hiddenFileInput > input {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.hiddenFileInput {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 150px;
  height: 150px;
  display: inline-block;
  overflow: hidden;

  /*for the background, optional*/
  background: center center no-repeat;
  background-size: 100%;
}
.hiddenFileInputNews > input {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.hiddenFileInputNews {
  /* border: 1px solid #ccc; */
  /* border-radius: 8px; */
  width: 587px;
  height: 330px;
  display: inline-block;
  overflow: hidden;

  /*for the background, optional*/
  background: center center no-repeat;
  background-size: 100%;
}
.hiddenFileInputQuota > input {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.hiddenFileInputQuota {
  width: 230px;
  height: 230px;
  display: inline-block;
  overflow: hidden;
  background: center center no-repeat;
  background-size: 100%;
}
.zoom {
  padding: 50px;
  transition: transform 0.2s;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.05);
}

.hiddenFileBtn > input {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.hiddenFileBtn {
  border: 0.5px solid #ccc;
  border-radius: 4px;
  width: 135px;
  height: 29px;
  display: inline-block;
  overflow: hidden;

  /*for the background, optional*/
  background: center center no-repeat;
  background-size: 100%;
}
.hiddenFileInputTask {
  border-radius: 8px;
  width: 240px;
  height: 75px;
  display: flex;
  overflow: hidden;
  background: center center no-repeat;
  background-size: 100%;
}
.hiddenFileInputTask > input {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.hiddenFileInputHighLight > input {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.hiddenFileInputHighLight {
  width: 264px !important;
  height: 330px !important;
  display: inline-block;
  overflow: hidden;
  background: center center no-repeat;
  background-size: 100%;
}
.hiddenFileInputGuru > input {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.hiddenFileInputGuru {
  width: 310px;
  height: 310px;
  display: inline-block;
  overflow: hidden;
  background: center center no-repeat;
  background-size: 100%;
}

.hiddenFileInputVideoGuru > input {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.hiddenFileInputVideoGuru {
  width: 275px;
  height: 490px;
  display: inline-block;
  overflow: hidden;
  background: center center no-repeat;
  background-size: 100%;
}
