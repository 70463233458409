@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

html {
  font-size: 14px;
  height: 100%;
  line-height: 1.8;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: 'Prompt' !important;
  font-weight: 400 !important;
  color: #000;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

table tr td :hover {
  font-weight: normal !important;
}
.table-head-role td {
  background-color: #eef6f0 !important;
}
.table-row-old td {
  background-color: rgba(244, 132, 26, 0.2) !important;
}

.table-row-older td {
  background-color: rgba(235, 87, 87, 0.3) !important;
}

.table-row-lasted td {
  background-color: rgb(255, 255, 255) !important;
}

.table-row-wait-review td {
  background-color: #2f80ed1a !important;
}

.table-row-wait-approve td {
  background-color: #2f80ed33 !important;
}

.table-row-approve td {
  background-color: #56ccf21a !important;
}

.ant-layout-sider-light {
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.ant-table-container table thead tr th {
  background-color: #219653 !important;
  font-family: 'Prompt' !important;
  font-weight: 500 !important;
  color: rgb(255, 255, 255) !important;
  font-weight: bold !important;
}

.custom-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #219653;
  border-color: #219653;
}

.custom-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #219653;
}

.custom-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #219653;
  top: -4.5px;
}

.custom-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: #c6c6c6;
}

.custom-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: #219653;
}

.text-left {
  text-align: left;
  margin: 0;
}

.ant-popover-title {
  background-color: #219653;
}

.ant-spin-dot-item {
  background-color: #219653 !important;
}

.ant-spin-text {
  color: #219653;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #219653 !important;
  border-color: #219653 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #219653 !important;
}

.ant-select-item-option-content {
  width: 100% !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #219653 !important;
}

.ant-tabs-tab-active {
  border-color: #219653 !important;
  border-radius: 4px 4px 0px 0px !important;
}

.ant-tabs-tab {
  border-radius: 4px 4px 0px 0px !important;
  margin-right: 4px !important;
}

.ant-tabs-tab-btn {
  color: #404040 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #219653 !important;
}

.ant-tabs-nav-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  border-bottom: 0.5px solid #ccc;
  z-index: 999;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000 !important;
}
.tab-status-inactive .ant-tabs-tab-active {
  border-color: #eb5757 !important;
  border-radius: 4px 4px 0px 0px !important;
}
.tab-status-inactive .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #eb5757 !important;
}

.ant-slider-track-1 {
  background-color: #219653 !important;
}

.ant-input-disabled,
.ant-input-suffix,
.ant-select-selection-item {
  color: #000 !important;
}

.ant-picker-input > input[disabled] {
  color: #000 !important;
}
